.item {
  transition:
    height 0.45s ease-in-out,
    opacity 0.3s ease-in-out;
}

.collapsed {
  @apply h-0 overflow-hidden opacity-0;
}

.expanded {
  @apply opacity-100;
}
